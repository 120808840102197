import { useState, useEffect  } from 'react';
import React from 'react';
import "../styles/Home.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import myLogo3 from '../images/logo3.png'
import { useTranslation } from 'react-i18next';
import HolidayModal from './HolidayModal.js';




  


const Home = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Check if the modal has been opened before
    const hasModalBeenOpenedBefore = localStorage.getItem('hasModalBeenOpened');

    // If not opened before, open the modal and set a flag in local storage
    if (!hasModalBeenOpenedBefore) {
      setModalOpen(true);
      localStorage.setItem('hasModalBeenOpened', 'true');
    }
  }, []);



  return (
    
    <Box>  
       
       <Typography variant="h5" color="text.primary" gutterBottom align="center" sx={{mt: {lg: "3vh", md: "3vh", xs:"2vh"},display: 'flex', justifyContent: 'center', width: '100%'}}>
      <img
          
          style={{display : 'block',}}
            height={50}
            width={71}
            src={myLogo3} alt="Logo"></img>
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
       {t('whatWeDo')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h5"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{ px: { lg:"20vw", md:"10vw", xs:"3vw",}, fontSize:{ lg:"1.5rem", md:"1.5rem", xs:"1.25rem"}}}
      >
        {t('whatWeDoText')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '5vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('offer')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h5"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{ px: { lg:"20vw", md:"10vw", xs:"3vw"}, fontSize:{ lg:"1.5rem", md:"1.5rem", xs:"1.25rem"}}}
      >
        {t('offerText')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '5vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
       {t('offerJob')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h5"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{ px: { lg:"20vw", md:"10vw", xs:"3vw"}, fontSize:{ lg:"1.5rem", md:"1.5rem", xs:"1.25rem"}}}
      >
        {t('offerJobText')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '5vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('offerEmploy')}
      </Typography>
      <Typography
        fontWeight={300}
        
        color="text.primary"
        gutterBottom
        align="center"
        sx={{px: { lg:"20vw", md:"10vw", xs:"3vw"}, fontSize:{ lg:"1.5rem", md:"1.5rem", xs:"1.25rem"}}}
      >
        {t('offerEmployText')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '5vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('range')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h5"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{ px: { lg:"20vw", md:"10vw", xs:"3vw"}, fontSize:{ lg:"1.5rem", md:"1.5rem", xs:"1.25rem"}, mb:"5vh"}}
      >
        {t('rangeText')}
      </Typography>
    </Box>
  );
};

export default Home;
